import { Col, message, Row } from 'antd';
import { useMutation, useReactiveVar } from '@apollo/client';
import {
  Group,
  JOIN_GROUP,
  LoggedInUser,
  Mutation,
  MutationJoinGroupArgs,
} from '../../../graphql';
import useBreakpoint from 'antd/es/grid/hooks/useBreakpoint';
import {
  LoginToContinueModal,
  OutlinedButton,
  PrimaryButton,
} from '../../../components';
import { PlusOutlined, ShareAltOutlined } from '@ant-design/icons';
import {
  GroupMemberOptions,
  GroupOwnerOptions,
  ShareGroupModal,
} from './index';
import { useState } from 'react';

type Props = { group: Group; refetch: () => void };

export default function GroupActions({ group, refetch }: Props) {
  const user = useReactiveVar(LoggedInUser);
  const { sm, md } = useBreakpoint();
  const [share, setShare] = useState<boolean>(false);
  const [showLogin, setShowLogin] = useState<boolean>(false);
  const [joinGroup, { loading: joining }] = useMutation<
    Mutation,
    MutationJoinGroupArgs
  >(JOIN_GROUP);

  const handleJoin = () => {
    if (!user?.id) {
      setShowLogin(true);
    } else {
      joinGroup({
        variables: { groupId: group.id },
      }).then(() => {
        message.success('Joined!');
        refetch();
      });
    }
  };

  const ShareModal = () => {
    return share ? (
      <ShareGroupModal
        groupIdentifier={group?.identifier as string}
        handleClose={() => setShare(false)}
      />
    ) : (
      <></>
    );
  };

  if (group?.owner.id === user?.id) {
    return (
      <Row gutter={[8, 8]} justify={md ? 'end' : 'start'}>
        <Col {...(!sm && { span: 24 })}>
          {/* <PrimaryButton icon={<PlusOutlined />} block={!sm}>
            Invite (soon)
          </PrimaryButton> */}
        </Col>
        <Col {...(!sm && { span: 12 })}>
          <PrimaryButton
            icon={<ShareAltOutlined />}
            block={!sm}
            onClick={() => setShare(true)}
          >
            Share
          </PrimaryButton>
        </Col>
        <Col {...(!sm && { span: 12 })}>
          <GroupOwnerOptions group={group} refetch={refetch} />
        </Col>
        <ShareModal />
      </Row>
    );
  } else if (group?.isMember) {
    return (
      <Row gutter={[8, 8]} justify={md ? 'end' : 'start'}>
        <Col {...(!sm && { span: 24 })}>
          {/* <PrimaryButton icon={<PlusOutlined />} block={!sm}>
            Invite (soon)
          </PrimaryButton> */}
        </Col>
        <Col {...(!sm && { span: 12 })}>
          <PrimaryButton
            icon={<ShareAltOutlined />}
            block={!sm}
            onClick={() => setShare(true)}
          >
            Share
          </PrimaryButton>
        </Col>
        <Col {...(!sm && { span: 12 })}>
          <GroupMemberOptions group={group} refetch={refetch} />
        </Col>
        <ShareModal />
      </Row>
    );
  } else {
    return (
      <Row gutter={[8, 8]} justify={md ? 'end' : 'start'}>
        <Col {...(!sm && { span: 12 })}>
          <PrimaryButton
            icon={<PlusOutlined />}
            block={!sm}
            loading={joining}
            onClick={handleJoin}
          >
            Join group
          </PrimaryButton>
        </Col>
        <Col {...(!sm && { span: 12 })}>
          <OutlinedButton
            icon={<ShareAltOutlined />}
            block={!sm}
            onClick={() => setShare(true)}
          >
            Share
          </OutlinedButton>
        </Col>
        <ShareModal />
        {showLogin && (
          <LoginToContinueModal handleClose={() => setShowLogin(false)} />
        )}
      </Row>
    );
  }
}
