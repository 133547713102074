import React, { useMemo, useState } from 'react';
import { Text, PrimaryButton } from '../../components';
import { Input, Form, Select, Row } from 'antd';
import AuthLayout from './AuthLayout';
import { useNavigate } from 'react-router-dom';
import { useMutation } from '@apollo/client';
import {
  Mutation,
  MutationRegisterArgs,
  REGISTER,
  UserRole,
} from '../../graphql';
import { colors } from '../../utils';
import logo_svg from '../../assets/svg/logo-svg.svg';
import { RoleHelpModal } from '.';

type Role = 'STORYTELLER' | 'GUARDIAN' | 'ADVOCATE' | 'AMBASSADOR';
type FieldType = {
  email: string;
  role: UserRole;
  firstName: string;
  lastName: string;
  password: string;
  confirmPassword?: string;
};

const SignUp = () => {
  const navigate = useNavigate();
  const [form] = Form.useForm();
  const roles: Role[] = useMemo(
    () => ['STORYTELLER', 'GUARDIAN', 'ADVOCATE', 'AMBASSADOR'],
    [],
  );
  const [register, { loading }] = useMutation<Mutation, MutationRegisterArgs>(
    REGISTER,
  );

  const [showRoleHelp, setShowRoleHelp] = useState(false);
  const [SignUpDone, setSignUpDone] = useState(false);

  const onFinish = async (values: FieldType) => {
    try {
      const { firstName, lastName, role, email, password } = values;
      const res = await register({
        variables: {
          userData: {
            firstName,
            lastName,
            role,
            email,
            password,
          },
        },
      });
      if (res.data?.register) {
        setSignUpDone(true);
      }
    } catch (error) {
      console.log('err register', error);
    }
  };

  return (
    <AuthLayout>
      {!SignUpDone ? (
        <Form
          form={form}
          className="sign-up-wrap"
          name="basic"
          style={{ width: '100%' }}
          onFinish={onFinish}
          autoComplete="off"
        >
          <div className="logo-wrap">
            <img
              src={logo_svg}
              alt={''}
              style={{ height: '38px', width: '38px' }}
            />
            <Text color={'black10'} fontSize={19} fontWeight={600}>
              Ocean Culture Life
            </Text>
          </div>
          <Text
            className="item-wrap1"
            color={'black10'}
            fontSize={20}
            fontWeight={500}
          >
            Welcome!
          </Text>
          <div className="item-wrap2">
            <Text fontSize={12} fontWeight={400} color={'black10'}>
              Already have an account?
            </Text>
            <Text
              fontSize={12}
              fontWeight={400}
              color={'blue6'}
              onClick={() => navigate('/login')}
            >
              Log in
            </Text>
          </div>
          <div className="item-wrap3">
            <Form.Item<FieldType>
              name="role"
              rules={[{ required: true, message: '' }]}
            >
              <Select
                style={{ textAlign: 'left', borderColor: colors.black5 }}
                placeholder={'Role'}
                onChange={(val) => console.log('select', val)}
              >
                {roles.map((role) => (
                  <Select.Option value={role} key={role}>
                    {role === 'AMBASSADOR'
                      ? 'Junior Ambassador'
                      : role[0] + role.slice(1).toLowerCase()}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
          </div>
          <Text
            className={'clickable'}
            onClick={() => setShowRoleHelp(true)}
            style={{
              alignSelf: 'end',
              marginTop: 4,
              textAlign: 'right',
              textDecorationLine: 'underline',
            }}
            color={'blue6'}
            fontSize={12}
          >
            Which role suits me?
          </Text>
          <div className="form-wrap">
            <div className="item-wrap4">
              <Form.Item<FieldType>
                name="firstName"
                rules={[{ required: true, message: '' }]}
              >
                <Input placeholder="First name" />
              </Form.Item>
              <Form.Item<FieldType>
                name="lastName"
                rules={[{ required: true, message: '' }]}
              >
                <Input placeholder="Last name" />
              </Form.Item>
            </div>
            <div className="item-wrap5">
              <Form.Item<FieldType>
                name="email"
                rules={[{ required: true, message: '' }]}
              >
                <Input type="email" placeholder="Email address" />
              </Form.Item>
            </div>
            <div className="item-wrap6">
              <Form.Item<FieldType>
                name="password"
                rules={[{ required: true, message: '' }]}
              >
                <Input.Password placeholder="Password" />
              </Form.Item>
            </div>
            <div className="item-wrap7">
              <Form.Item<FieldType>
                name="confirmPassword"
                rules={[
                  {
                    required: true,
                    message: '',
                  },
                  ({ getFieldValue }) => ({
                    validator(_, value) {
                      if (!value || getFieldValue('password') === value) {
                        return Promise.resolve();
                      }
                      return Promise.reject(
                        new Error('Passwords do not match!'),
                      );
                    },
                  }),
                ]}
              >
                <Input.Password placeholder="Confirm password" />
              </Form.Item>
            </div>
          </div>
          <div className="item-wrap8">
            <PrimaryButton htmlType="submit" loading={loading}>
              Sign up
            </PrimaryButton>
          </div>
        </Form>
      ) : (
        <div className="on-finish-wrap">
          <Row style={{ gap: '10px' }} justify={'center'}>
            <Text color={'black10'} fontSize={20} fontWeight={500}>
              Verify your email
            </Text>
            <Text
              color={'black5'}
              fontSize={12}
              fontWeight={400}
              style={{ textAlign: 'center', maxWidth: '333px' }}
            >
              We have sent you an email to verify your account. No email
              received? Please also check your spam folder.
            </Text>
          </Row>
          <PrimaryButton
            style={{ width: '100%', marginTop: '20px' }}
            onClick={() => navigate('/login')}
          >
            Log in
          </PrimaryButton>
        </div>
      )}
      {showRoleHelp && (
        <RoleHelpModal handleClose={() => setShowRoleHelp(false)} />
      )}
    </AuthLayout>
  );
};

export default SignUp;
