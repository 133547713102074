import { Card, Col, Flex, Row, Spin } from 'antd';
import {
  EventItem,
  FindAndSearchUsers,
  GroupItem,
  PrimaryButton,
  Text,
} from '../../../components';
import { useQuery } from '@apollo/client';
import {
  Event,
  GET_FEATURED_ITEMS,
  Query,
  User,
  UserRole,
} from '../../../graphql';
import useBreakpoint from 'antd/es/grid/hooks/useBreakpoint';
import { FeedItem } from '../Feed';
import { useNavigate } from 'react-router-dom';
import { useMemo } from 'react';
import {
  colors,
  getRoleText,
  getUserLocation,
  openUrlInNewTab,
} from '../../../utils';
import { urls } from '../Profile';

const Home = () => {
  const navigate = useNavigate();
  const { md } = useBreakpoint();
  const { data, loading, refetch } = useQuery<Query>(GET_FEATURED_ITEMS);

  const hasEvents = useMemo(
    () => !!data?.getFeaturedItems.events?.length,
    [data],
  );

  const hasUsers = useMemo(
    () => !!data?.getFeaturedItems.users?.length,
    [data],
  );

  const hasGroups = useMemo(
    () => !!data?.getFeaturedItems.groups?.length,
    [data],
  );

  const Spinner = () => (
    <Row style={{ padding: '6em' }} justify={'center'}>
      <Spin />
    </Row>
  );

  return (
    <Row gutter={[16, 20]}>
      <Col xs={24} lg={hasEvents || hasUsers ? 16 : 24}>
        <Row gutter={[16, 20]}>
          <Col span={24}>
            <Card
              className={'bg-5'}
              style={{ height: md ? 350 : 350, backgroundPosition: 'center' }}
              bodyStyle={{ display: 'grid', position: 'absolute', bottom: 0 }}
            >
              <Text variant={'heading3'} color={'white'}>
                {data?.getFeaturedItems.hero.heading}
              </Text>
              <Text
                color={'black1'}
                style={{ margin: '10px 0', maxWidth: 800 }}
              >
                {data?.getFeaturedItems.hero.description}
              </Text>
              <PrimaryButton
                height={32}
                fontSize={14}
                onClick={() =>
                  data?.getFeaturedItems.hero.buttonUrl
                    ? navigate(data?.getFeaturedItems.hero.buttonUrl)
                    : {}
                }
              >
                {data?.getFeaturedItems.hero.buttonText}
              </PrimaryButton>
            </Card>
          </Col>
          <Col span={24}>
            <FindAndSearchUsers />
          </Col>
          <Col xs={24} lg={0}>
            <Flex vertical gap={12}>
              {hasUsers && (
                <MemberOfTheMonth
                  loading={loading}
                  users={data?.getFeaturedItems.users ?? []}
                />
              )}
              {hasEvents && (
                <Events
                  bordered={false}
                  loading={loading}
                  events={data?.getFeaturedItems?.events ?? []}
                />
              )}
            </Flex>
          </Col>
          {hasGroups && (
            <Col span={24}>
              <Text variant={'smNormal'} color={'black4'}>
                Featured community groups
              </Text>
              <Row
                gutter={[12, 12]}
                style={{ marginTop: 4, padding: loading ? '6em' : 0 }}
                justify={loading ? 'center' : 'start'}
              >
                {loading ? (
                  <Spin />
                ) : (
                  data?.getFeaturedItems.groups?.map((group) => (
                    <Col xs={24} sm={8} key={group.id}>
                      <GroupItem group={group} refetch={refetch} />
                    </Col>
                  ))
                )}
              </Row>
            </Col>
          )}
          {(loading || !!data?.getFeaturedItems.stories?.length) && (
            <Col span={24} style={{ display: 'grid' }}>
              {loading ? (
                <Spinner />
              ) : !!data?.getFeaturedItems.stories?.length ? (
                <>
                  <Text variant={'smNormal'} color={'black4'}>
                    Featured posts and fundraisings
                  </Text>
                  <Row style={{ marginTop: 4 }} justify={'center'}>
                    {data?.getFeaturedItems.stories?.map((story) => (
                      <Col span={24} key={story.id}>
                        <FeedItem story={story} refetch={refetch} />
                      </Col>
                    ))}
                  </Row>
                </>
              ) : (
                <></>
              )}
            </Col>
          )}
        </Row>
      </Col>
      {hasEvents ||
        (hasUsers && (
          <Col xs={0} lg={8}>
            <Flex vertical gap={12}>
              {hasUsers && (
                <MemberOfTheMonth
                  bordered
                  loading={loading}
                  users={data?.getFeaturedItems.users ?? []}
                />
              )}
              {hasEvents && (
                <Events
                  bordered
                  loading={loading}
                  events={data?.getFeaturedItems?.events ?? []}
                />
              )}
            </Flex>
          </Col>
        ))}
    </Row>
  );
};

export default Home;

const Events = ({
  bordered,
  loading,
  events,
}: {
  bordered?: boolean;
  loading: boolean;
  events?: Event[];
}) => {
  return (
    <Card
      style={bordered ? {} : { border: 'none', boxShadow: 'none' }}
      bodyStyle={{
        padding: bordered ? 16 : 0,
      }}
    >
      <Row style={{ gap: 12, display: 'grid' }}>
        <Text variant={'lgStrong'}>Events</Text>
        <Row
          gutter={[12, 12]}
          style={{ marginTop: 4, padding: loading ? '6em' : 0 }}
          justify={loading ? 'center' : 'start'}
        >
          {loading ? (
            <Spin />
          ) : (
            events?.map((event) => (
              <Col xs={24} sm={8} lg={24} key={event.id}>
                <EventItem event={event} />
              </Col>
            ))
          )}
        </Row>
      </Row>
    </Card>
  );
};

const MemberOfTheMonth = ({
  bordered,
  loading,
  users,
}: {
  bordered?: boolean;
  loading: boolean;
  users: User[];
}) => {
  return (
    <Card
      style={bordered ? {} : { border: 'none', boxShadow: 'none' }}
      bodyStyle={{
        padding: bordered ? 16 : 0,
      }}
    >
      <Row style={{ gap: 12, display: 'grid' }}>
        <Text variant={'lgStrong'}>Member of the Month</Text>
        <Row
          gutter={[12, 12]}
          style={{ marginTop: 4, padding: loading ? '6em' : 0 }}
          justify={loading ? 'center' : 'start'}
        >
          {loading ? (
            <Spin />
          ) : (
            users?.map((user) => (
              <Col xs={24} key={user.id}>
                <Card styles={{ body: { padding: 0 } }}>
                  <img
                    src={user.profile?.banner as string}
                    alt={''}
                    style={{
                      borderTopRightRadius: 12,
                      borderTopLeftRadius: 12,
                      width: '100%',
                      height: 140,
                      objectFit: 'cover',
                      objectPosition: 'center',
                    }}
                  />
                  <Flex
                    style={{
                      padding: '0 24px',
                      position: 'relative',
                      marginTop: -62,
                    }}
                    justify={'space-between'}
                    align={'end'}
                  >
                    <img
                      src={user.profile?.picture as string}
                      alt={''}
                      style={{
                        objectPosition: 'center',
                        objectFit: 'cover',
                        borderRadius: '100%',
                        width: 120,
                        height: 120,
                        border: '4px solid white',
                      }}
                    />
                    <PrimaryButton
                      fontSize={14}
                      height={32}
                      onClick={() => {
                        const url = `/user/${user?.username}`;
                        window.open(url, '_blank');
                      }}
                    >
                      View profile
                    </PrimaryButton>
                  </Flex>

                  <Flex
                    vertical
                    style={{
                      padding: '16px 24px 24px',
                      position: 'relative',
                    }}
                  >
                    <Text variant={'xlStrong'}>
                      <Flex gap={8} align={'center'}>
                        {user.firstName + ' ' + user.lastName}
                        {user?.grantWinner && (
                          <img
                            src={require('../../../assets/images/badge.png')}
                            alt={''}
                            style={{ height: 18, width: 18 }}
                          />
                        )}
                      </Flex>
                    </Text>
                    <Text color={'black4'}>{getRoleText(user.role)}</Text>

                    <div
                      style={{
                        padding: '2px 10px',
                        background: colors.blue1,
                        borderRadius: 4,
                        marginTop: 6,
                        width: 'fit-content',
                      }}
                    >
                      <Text variant={'smMedium'} color={'blue6'}>
                        {getUserLocation(user.profile?.location)}
                      </Text>
                    </div>

                    <Flex style={{ marginTop: 16 }}>
                      {user?.role !== UserRole.Ambassador &&
                        !!urls &&
                        urls?.some(
                          ({ key }) =>
                            !!user.profile?.[key as keyof typeof user.profile],
                        ) && (
                          <Row gutter={[12, 12]}>
                            {urls.map((url) =>
                              !!user.profile?.[
                                url.key as keyof typeof user.profile
                              ] ? (
                                <Col key={url.key}>
                                  <Card
                                    onClick={() =>
                                      openUrlInNewTab(
                                        user.profile?.[
                                          url.key as keyof typeof user.profile
                                        ],
                                      )
                                    }
                                    className={'card-no-padding clickable'}
                                    bordered={false}
                                    style={{ boxShadow: 'none' }}
                                  >
                                    <img
                                      src={url.icon}
                                      alt={''}
                                      style={{ width: 22, height: 22 }}
                                    />
                                  </Card>
                                </Col>
                              ) : (
                                <></>
                              ),
                            )}
                          </Row>
                        )}
                    </Flex>
                  </Flex>
                </Card>
              </Col>
            ))
          )}
        </Row>
      </Row>
    </Card>
  );
};
