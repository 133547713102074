import { Card, Col, Row } from 'antd';
import { ProfileUser } from '../../../graphql';
import { useReactiveVar } from '@apollo/client';
import { useMemo } from 'react';
import { colors } from '../../../utils';

export default function Videos({ bordered = false }: { bordered?: boolean }) {
  const user = useReactiveVar(ProfileUser);

  const videoUrls = useMemo(
    () =>
      user?.profile?.storyteller?.videoUrls?.map((url) => {
        if (url.includes('youtu')) {
          if (url.includes('watch?v='))
            return `https://www.youtube.com/embed/${url.split('watch?v=')[1]}`;
          else if (url.includes('shorts'))
            return `https://www.youtube.com/embed/${url.split('shorts/')[1]}`;
          else
            return `https://www.youtube.com/embed/${url.split('youtu.be/')[1]}`;
        } else if (url.includes('vimeo')) {
          return `https://player.vimeo.com/video/${url.split('vimeo.com/')[1]}`;
        }
      }) ?? [],
    [user],
  );

  return (
    <Card bordered={bordered} style={bordered ? {} : { boxShadow: 'none' }}>
      <Row gutter={[16, 16]}>
        {videoUrls?.map((url) => (
          <Col span={24} key={url}>
            <iframe
              width="100%"
              style={{
                borderRadius: 12,
                aspectRatio: 5 / 3,
                border: `1px solid ${colors.black1}`,
              }}
              src={url}
            />
          </Col>
        ))}
      </Row>
    </Card>
  );
}
