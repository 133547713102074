import { gql } from '@apollo/client';

export const LOGIN = gql`
  mutation Login($userData: LoginInput!) {
    login(userData: $userData) {
      accessToken
      refreshToken
      user {
        id
        firstName
        lastName
        username
        email
        role
        status
        createdAt
        updatedAt
      }
    }
  }
`;

export const REGISTER = gql`
  mutation Register($userData: RegisterInput!) {
    register(userData: $userData)
  }
`;

export const FORGOT_PASSWORD = gql`
  mutation forgotPassword($email: String!) {
    forgotPassword(email: $email)
  }
`;

export const VALIDATE_RESET_PASSWORD = gql`
  mutation validateResetPassword($verificationId: String!) {
    validateResetPassword(verificationId: $verificationId)
  }
`;

export const RESET_PASSWORD = gql`
  mutation resetPassword($verificationId: String!, $password: String!) {
    resetPassword(verificationId: $verificationId, password: $password)
  }
`;

export const UPDATE_USER = gql`
  mutation updateUser($userUpdate: UpdateUserInput!, $forReview: Boolean) {
    updateUser(userUpdate: $userUpdate, forReview: $forReview)
  }
`;

export const VALIDATE_USER_EMAIL = gql`
  mutation validateUserEmail($verificationId: String!) {
    validateUserEmail(verificationId: $verificationId)
  }
`;

export const CREATE_STORY = gql`
  mutation createStory($story: CreateStoryInput!) {
    createStory(story: $story)
  }
`;

export const UPDATE_STORY = gql`
  mutation updateStory($storyId: String!, $update: UpdateStoryInput!) {
    updateStory(storyId: $storyId, update: $update)
  }
`;

export const DELETE_STORY = gql`
  mutation deleteStory($storyId: String!) {
    deleteStory(storyId: $storyId)
  }
`;

export const LIKE_STORY = gql`
  mutation likeStory($storyId: String!, $like: Boolean!) {
    likeStory(storyId: $storyId, like: $like)
  }
`;

export const ADD_COMMENT = gql`
  mutation addComment($storyId: String!, $comment: String!) {
    addComment(storyId: $storyId, comment: $comment)
  }
`;

export const UPDATE_COMMENT = gql`
  mutation updateComment($commentId: String!, $update: String!) {
    updateComment(commentId: $commentId, update: $update)
  }
`;

export const DELETE_COMMENT = gql`
  mutation deleteComment($commentId: String!) {
    deleteComment(commentId: $commentId)
  }
`;

export const UPLOAD_FILE = gql`
  mutation uploadFile($file: Upload!) {
    uploadFile(file: $file)
  }
`;

export const UPLOAD_MULTIPLE_FILES = gql`
  mutation uploadMultipleFiles($files: [Upload!]!) {
    uploadMultipleFiles(files: $files)
  }
`;

export const CREATE_GROUP = gql`
  mutation createGroup($group: CreateGroupInput!) {
    createGroup(group: $group)
  }
`;

export const UPDATE_GROUP = gql`
  mutation updateGroup($groupId: String!, $update: UpdateGroupInput!) {
    updateGroup(groupId: $groupId, update: $update)
  }
`;

export const DELETE_GROUP = gql`
  mutation deleteGroup($groupId: String!) {
    deleteGroup(groupId: $groupId)
  }
`;

export const JOIN_GROUP = gql`
  mutation joinGroup($groupId: String!) {
    joinGroup(groupId: $groupId)
  }
`;

export const LEAVE_GROUP = gql`
  mutation leaveGroup($groupId: String!) {
    leaveGroup(groupId: $groupId)
  }
`;

export const CREATE_GROUP_STORY = gql`
  mutation createGroupStory($groupId: String!, $story: CreateStoryInput!) {
    createGroupStory(groupId: $groupId, story: $story)
  }
`;

export const CREATE_EVENT = gql`
  mutation createEvent($event: CreateEventInput!) {
    createEvent(event: $event)
  }
`;

export const UPDATE_EVENT = gql`
  mutation updateEvent($eventId: String!, $update: UpdateEventInput!) {
    updateEvent(eventId: $eventId, update: $update)
  }
`;

export const DELETE_EVENT = gql`
  mutation deleteEvent($eventId: String!) {
    deleteEvent(eventId: $eventId)
  }
`;

export const JOIN_EVENT = gql`
  mutation joinEvent($eventId: String!) {
    joinEvent(eventId: $eventId)
  }
`;

export const LEAVE_EVENT = gql`
  mutation leaveEvent($eventId: String!) {
    leaveEvent(eventId: $eventId)
  }
`;

export const CREATE_EVENT_STORY = gql`
  mutation createEventStory($eventId: String!, $story: CreateStoryInput!) {
    createEventStory(eventId: $eventId, story: $story)
  }
`;
