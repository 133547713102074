import { CheckOutlined, CopyOutlined } from '@ant-design/icons';
import { colors } from './constants';
import { RcFile, UploadProps } from 'antd/es/upload';
import { message } from 'antd';
import { isArray } from 'lodash';

export const renderHtmlString = (str: string) => (
  <div dangerouslySetInnerHTML={{ __html: str }} />
);

export const copyable = (color: string = colors.primary500) => ({
  icon: [
    // @ts-ignore
    <CopyOutlined style={{ color }} />,
    // @ts-ignore
    <CheckOutlined style={{ color }} />,
  ],
});

export function getCookie(name: string) {
  const cookies = document.cookie.split(';');
  for (let i = 0; i < cookies.length; i++) {
    const cookie = cookies[i].trim();
    if (cookie.startsWith(name + '=')) {
      return cookie.substring(name.length + 1);
    }
  }
  return undefined;
}

export function openUrlInNewTab(link: string) {
  // Check if the link starts with 'http' or 'https'
  if (!link.startsWith('http://') && !link.startsWith('https://')) {
    // If not, prepend 'http://' to the link
    link = 'http://' + link;
  }

  // Open the link in a new tab
  const newTab = window.open(link, '_blank', 'noopener,noreferrer');
}

export function leftPad(number: number, targetLength: number) {
  var output = number + '';
  while (output.length < targetLength) {
    output = '0' + output;
  }
  return output;
}

export function addEllipsisToText(address: any, beforeLength: number) {
  const length = address.length;
  const start = address.slice(0, beforeLength);
  const end = address.slice(length - 3, length);
  return `${start}...${end}`;
}

export const getRemInPixels = (rem: number = 1) => {
  const temporaryElement = document.createElement('div');
  temporaryElement.style.width = `${rem}rem`;
  document.body.appendChild(temporaryElement);
  const itemWidthPixels = temporaryElement.offsetWidth;
  document.body.removeChild(temporaryElement);
  return itemWidthPixels;
};

export const maskAddressOrEmail = (input: any): string => {
  if (!input) return '';
  const MASK_CHAR = '*';
  if (input.includes('@')) {
    // For email addresses
    const [localPart, domain] = input.split('@');
    const maskedLocalPart =
      localPart.substring(0, 2) + MASK_CHAR.repeat(4) + localPart.slice(-2);
    return `${maskedLocalPart}@${domain}`;
  } else if (input.startsWith('0x')) {
    // For wallet addresses
    const maskedAddress =
      input.substring(0, 6) + MASK_CHAR.repeat(4) + input.slice(-4);
    return maskedAddress;
  }

  return input; // Return unchanged if not recognized as email or wallet address
};

export const clean = (obj: any) => {
  for (let propName in obj) {
    if (
      obj[propName] === '' ||
      obj[propName] === null ||
      obj[propName] === undefined
    ) {
      delete obj[propName];
    }
  }
  return obj;
};

const getFileType = (url: string) => {
  const splitUrl = url.split('.');

  return splitUrl[splitUrl.length - 1];
};

export const downloadFile = (url: string) => {
  let a = document.createElement('a');
  a.href = url;
  a.id = url;
  a.download = getFileType(url);
  a.click();
};

export const downloadCreation = (url: string) => {
  fetch(url, {
    method: 'GET',
    headers: {},
  })
    .then((response) => {
      response.arrayBuffer().then(function (buffer) {
        const url = window.URL.createObjectURL(new Blob([buffer]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', getFileType(url));
        document.body.appendChild(link);
        link.click();
      });
    })
    .catch((err) => {
      console.log(err);
    });
};

export const cleanObject = (obj: any) => {
  for (const key in obj) {
    if (obj[key] === undefined) {
      delete obj[key];
    } else if (typeof obj[key] === 'object') {
      cleanObject(obj[key]);
      if (Object.keys(obj[key]).length === 0) {
        delete obj[key];
      }
    }
  }
  return obj;
};

export const getInitials = (firstName: string = '', lastName: string = '') => {
  return (firstName[0] + lastName[0])?.toUpperCase() ?? '';
};

export const getVideoUrl = (url: string) => {
  return `${url}#t=0.001`;
};

export const BeforeUpload = (
  file: RcFile | RcFile[],
  handleError?: () => void,
  getFiles?: (files: RcFile[]) => void,
) => {
  const isFileValid = (singleFile: RcFile) => {
    if (singleFile.type.includes('image')) {
      const isMoreThan2M = singleFile.size / 1024 / 1024 > 2;
      if (isMoreThan2M) {
        message.error('Image must be smaller than 2MB!');
        handleError?.();
      }

      return !isMoreThan2M;
    } else if (singleFile.type.includes('video')) {
      const isMoreThan5M = singleFile.size / 1024 / 1024 > 5;
      if (isMoreThan5M) {
        message.error('Video must be smaller than 5MB!');
        handleError?.();
      }

      return !isMoreThan5M;
    }

    return false;
  };

  if (isArray(file)) {
    const valid: RcFile[] = [];

    file.forEach((singleFile) => {
      if (isFileValid(singleFile)) valid.push(singleFile);
    });

    getFiles?.(valid);

    return true;
  } else {
    return isFileValid(file);
  }
};

export const commonUploadProps: UploadProps = {
  name: 'file',
  multiple: false,
  accept: 'image/png,image/jpg,image/jpeg',
  showUploadList: false,
  beforeUpload: (file: RcFile) => BeforeUpload(file as RcFile),
};

export const previewImage = (file: any, onLoad: (result: string) => void) => {
  const oFReader = new FileReader();
  oFReader.readAsDataURL(file);

  oFReader.onload = function (oFREvent) {
    onLoad(oFREvent?.target?.result as string);
  };
};

export const previewImages = (
  files: any[],
  onLoad: (result: string) => void,
) => {
  files.forEach((file) => {
    var oFReader = new FileReader();
    oFReader.readAsDataURL(file);

    oFReader.onload = function (oFREvent) {
      onLoad(oFREvent?.target?.result as string);
    };
  });
};

export const getUserLocation = (location?: string | null) => {
  if (location) {
    return JSON.parse(location).label;
  }
  return '';
};

export const parseNameForUrl = (text: string) => {
  return text.trim().split(' ').join('_');
};
