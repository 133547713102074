import { Col, Row, Spin } from 'antd';
import {
  GET_STORIES_BY_USER,
  LoggedInUser,
  ProfileUser,
  Query,
  QueryGetStoriesByUserArgs,
} from '../../../graphql';
import { useQuery, useReactiveVar } from '@apollo/client';
import { FeedItem } from '../Feed';
import { ShareAPost } from '../Feed/Feed';
import { Text } from '../../../components';

export default function Posts() {
  const loggedInUser = useReactiveVar(LoggedInUser);
  const user = useReactiveVar(ProfileUser);
  const { data, loading, refetch } = useQuery<Query, QueryGetStoriesByUserArgs>(
    GET_STORIES_BY_USER,
    {
      variables: {
        userId: user?.id ?? '',
      },
    },
  );

  return loading ? (
    <Row style={{ margin: '3em' }} justify={'center'}>
      <Spin />
    </Row>
  ) : (
    <Row gutter={[0, 16]}>
      {loggedInUser?.id === user?.id && (
        <ShareAPost refetch={refetch} user={user} />
      )}
      {!!data?.getStoriesByUser?.data?.length ? (
        data?.getStoriesByUser?.data?.map((story) => (
          <Col span={24} key={story.id}>
            <FeedItem story={story} refetch={refetch} />
          </Col>
        ))
      ) : (
        <Col span={24} style={{ textAlign: 'center', padding: '3em 2em' }}>
          <Text fontSize={14} fontWeight={500} color={'black7'}>
            No posts found
          </Text>
        </Col>
      )}
    </Row>
  );
}
