import { Avatar, Col, Divider, Flex, Modal, Row, Spin } from 'antd';
import { OutlinedButton, Text } from '../../../components';
import { useQuery } from '@apollo/client';
import {
  GET_STORY_LIKES,
  Like,
  Query,
  QueryGetStoryLikesArgs,
  Story,
} from '../../../graphql';
import { useEffect, useState } from 'react';
import { getInitials } from '../../../utils';

type Props = { story: Story; handleClose: () => void };

const StoryLikesModal = ({ story, handleClose }: Props) => {
  const [likes, setLikes] = useState<Like[]>([]);
  const { data, loading } = useQuery<Query, QueryGetStoryLikesArgs>(
    GET_STORY_LIKES,
    { variables: { storyId: story.id } },
  );

  useEffect(() => {
    if (data?.getStoryLikes) setLikes(data.getStoryLikes);
  }, [data]);

  return (
    <Modal
      open
      centered
      closable={false}
      footer={null}
      title={null}
      onCancel={handleClose}
    >
      <Row gutter={[0, 16]}>
        <Col span={24}>
          <Text fontSize={20} fontWeight={600} color={'black10'}>
            {`${story.likes?.length ?? '0'} Like${
              story.likes?.length === 1 ? '' : 's'
            }`}
          </Text>
        </Col>
        <Divider style={{ margin: 0 }} />
        <Col
          span={24}
          className={'scroll-container'}
          style={{
            maxHeight: '50vh',
            overflowY: 'scroll',
            overflowX: 'hidden',
          }}
        >
          {loading ? (
            <Row justify={'center'} style={{ margin: '3em' }}>
              <Spin size={'large'} />
            </Row>
          ) : !!likes.length ? (
            <Row gutter={[0, 16]}>
              {likes?.map((like) => (
                <Col span={24}>
                  <Row align={'middle'} gutter={16}>
                    <Col>
                      <Avatar size={40} src={like.user.profile?.picture}>
                        {getInitials(like.user.firstName, like.user.lastName)}
                      </Avatar>
                    </Col>
                    <Col>
                      <Text fontSize={16} fontWeight={500}>
                        <Flex gap={8} align={'center'}>
                          {like.user.firstName + ' ' + like.user.lastName}
                          {like.user?.grantWinner && (
                            <img
                              src={require('../../../assets/images/badge.png')}
                              alt={''}
                              style={{ height: 18, width: 18 }}
                            />
                          )}
                        </Flex>
                      </Text>
                    </Col>
                  </Row>
                </Col>
              ))}
            </Row>
          ) : (
            <Row gutter={[0, 16]} justify={'center'}>
              <Text color={'black5'} style={{ textAlign: 'center' }}>
                This story does not have any likes yet
              </Text>
            </Row>
          )}
        </Col>
      </Row>
    </Modal>
  );
};

export default StoryLikesModal;
