import { Card, Col, Row } from 'antd';
import { Text } from '../Text';
import { Event } from '../../graphql';
import { format } from 'date-fns';
import { useNavigate } from 'react-router-dom';
import { parseNameForUrl } from '../../utils';

type Props = { event: Event };

export default function EventItem({ event }: Props) {
  const navigate = useNavigate();

  return (
    <Card
      className={'card-no-padding clickable event-card'}
      onClick={() => {
        navigate(`/events/${event.identifier}`);
      }}
    >
      <Row>
        <Col span={24}>
          <img
            src={event.banner}
            alt={''}
            style={{
              width: '100%',
              aspectRatio: 2,
              objectFit: 'cover',
              borderTopLeftRadius: 12,
              borderTopRightRadius: 12,
            }}
          />
        </Col>
        <Col span={24} style={{ padding: '12px 16px', display: 'grid' }}>
          <Text variant={'smNormal'}>
            {!!event?.start
              ? `${format(new Date(Number(event.start)), 'MMM, d')}${
                  !!event.end
                    ? ` - ${format(new Date(Number(event.end)), 'MMM, d')}`
                    : ''
                }`
              : 'May, 20 - May, 23'}
          </Text>
          <Text variant={'baseStrong'} style={{ marginTop: 4 }}>
            {event?.title}
          </Text>
          <Text variant={'smMedium'} color={'black6'}>
            {event?.location?.title}, {event?.location?.description}
          </Text>
          <Text
            variant={'smNormal'}
            color={event.isMember ? 'blue6' : 'black4'}
            style={{ marginTop: 6 }}
          >
            {event.isMember
              ? !!event.membersCount && event.membersCount > 1
                ? `You and ${event.membersCount - 1} members are going`
                : `You are going`
              : `${event.membersCount} members are going`}
          </Text>
        </Col>
      </Row>
    </Card>
  );
}
