import { Col, Divider, Modal, Row } from 'antd';
import { PrimaryButton, Text } from '../../../components';
import { colors } from '../../../utils';

type Props = { eventIdentifier: string; handleClose: () => void };

const ShareEventModal = ({ eventIdentifier, handleClose }: Props) => {
  return (
    <Modal
      open
      centered
      closable={false}
      footer={null}
      title={null}
      onCancel={handleClose}
    >
      <Row gutter={[0, 16]}>
        <Col span={24}>
          <Text fontSize={20} fontWeight={600} color={'black10'}>
            Share event
          </Text>
        </Col>
        <Divider style={{ margin: 0 }} />
        <Col
          span={24}
          style={{
            marginTop: 4,
            borderRadius: 6,
            background: colors.black1,
            padding: 4,
            paddingLeft: 10,
          }}
        >
          <Text
            style={{
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
            }}
            copyable={{
              tooltips: false,
              icon: [
                <PrimaryButton height={32} fontSize={14}>
                  Copy
                </PrimaryButton>,
                <PrimaryButton height={32} fontSize={14}>
                  Copied!
                </PrimaryButton>,
              ],
            }}
          >{`https://app.oceanculturelife.com/events/${eventIdentifier}`}</Text>
        </Col>
      </Row>
    </Modal>
  );
};

export default ShareEventModal;
